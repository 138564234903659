import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "..";

export const selectMainState = createFeatureSelector<AppState>("app");
export const customerBasicInformation = createSelector(
    selectMainState,
    state => state.user
);
export const userEmail = createSelector(
    customerBasicInformation,
    u => u.email);
export const userDisplayName = createSelector(
    customerBasicInformation,
    u => `${u.name} ${u.lastname}`);
export const userId = createSelector(
    customerBasicInformation,
    u => u.uid);
export const userCommercialName = createSelector(
    customerBasicInformation,
    u => u.commercialName);
export const selectUrlLogo = createSelector(
    customerBasicInformation,
    state => state.urlLogo
);
<div class="app-admin-wrap layout-sidebar-compact clearfix sidebar-midnight-blue"
[ngClass]="{'sidenav-open': navService.sidebarState.sidenavOpen}">
    <div class="side-content-wrap" [perfectScrollbar]="{suppressScrollY: true}">
        <app-sidebar-compact></app-sidebar-compact>
    </div>
    <div class="main-content-wrap d-flex flex-column" [perfectScrollbar]="{suppressScrollX: true}">
        <app-header-sidebar-compact></app-header-sidebar-compact>
        <div class="main-content">
            <div aria-live="polite" aria-atomic="true" toastContainer></div>
            <router-outlet></router-outlet>
        </div>
        <div class="flex-grow-1"></div>
        <app-footer></app-footer>
        <div class="module-loader" *ngIf="moduleLoading">
            <div class="spinner spinner-bubble spinner-bubble-primary me-3"></div>
        </div>
    </div>
</div>
<app-search *ngIf="searchService.searchOpen"></app-search>
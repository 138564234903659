
import { createAction, props } from "@ngrx/store";

export const login = createAction(
    '[Login]',
    props<{ credentials: any,
        urlRedirect: string
     }>());
export const loginSuccess = createAction(
    '[Login] Success',
    props<{ userInfo: any, urlRedirect:string }>());
export const loginFail = createAction(
    '[Login] Fail',
    props<{ error: any }>()
);
export const logout = createAction('[Logout]');
export const userInfo = createAction(
    '[API/User] Load information',
    props<{uid: string}>());
export const userInfoSuccess = createAction(
    '[API/User] Information success',
    props<{ userInfo: any }>()
);
export const userInfoFail = createAction(
    '[API/User] Information fail',
    props<{err: any}>()
);

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCnbXjT6_i1efG_SG1FDOsgMo0DJi5abZY",
    authDomain: "api-stopandbook.firebaseapp.com",
    projectId: "api-stopandbook",
    storageBucket: "api-stopandbook.appspot.com",
    messagingSenderId: "609854971164",
    appId: "1:609854971164:web:b87d84ff3772a5898a6ed9",
    measurementId: "G-QZG4J0KNVW"
  },
  services: {
    backend: "https://api.stopandbook.com",
    payment: "https://api.stopandbook.com/payment"
  }
};

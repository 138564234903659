import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, firstValueFrom, from, lastValueFrom } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthBearerInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.addBearerToken(request, next));
  }

  async addBearerToken(request: HttpRequest<unknown>, next: HttpHandler) {
    if (request.url.startsWith(environment.services.backend) ||
      request.url.startsWith(environment.services.payment)) {
      const token = await this.auth.idToken();
      const cloned = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
      const r = await lastValueFrom(next.handle(cloned));
      return r;
    }

    const s =  await lastValueFrom(next.handle(request));
    return s;
  }
}

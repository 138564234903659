import { createSelector } from "@ngrx/store"; 
import { AppState } from "..";

export const loginState = (state: AppState) => state.login;
export const loginLoading = createSelector(
    loginState,
    state => state.loading);
export const loginLoadingText = createSelector(
    loginState,
    state => state.loadingText
);
export const loginLogged = createSelector(
    loginState,
    state => state.logged
);

import { Injectable, NgZone } from "@angular/core";
import { CustomerService } from "src/app/shared/services/customer.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserActions } from "../actions";
import { catchError, from, map, mergeMap, of, switchMap, tap } from "rxjs";
import { UserCredential } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { LocalStoreService } from "src/app/shared/services/local-store.service";

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private customerService: CustomerService,
        private authService: AuthService,
        private router: Router,
        private zone: NgZone,
        private ls: LocalStoreService
    ) {

    }

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.login),
            switchMap((action) => this.authService.signin(action.credentials).pipe(
                map(async(r: UserCredential) => {
                    const token = await r.user.getIdToken(true);
                    this.ls.setItem("t", token);
                    this.ls.setItem("createdAt", Date.now());

                    return {
                        userInfo: {
                            email: r.user.email,
                            displayName: r.user.displayName,
                            uid: r.user.uid,
                            photoURL: r.user.photoURL
                        },
                        urlRedirect: action.urlRedirect
                    };
                }),
                mergeMap(a => from(a).pipe(
                    map(b => UserActions.loginSuccess(b))
                )),
                catchError((e) => of(UserActions.loginFail({ error: e.message })))
            )))
    });

    loginSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        tap(action => {
            this.router.navigateByUrl(action.urlRedirect);
        })
    ),{dispatch: false});

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.logout),
            tap(() => this.authService.signout())
        )
    },
        {
            dispatch: false
        });

    userInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.userInfo),
            switchMap(() => this.customerService.userById().pipe(
                map(r => UserActions.userInfoSuccess({ userInfo: {
                    address: r.profile.basicInformation.address,
                    commercialName: r.profile.basicInformation.commercialName,
                    email: r.profile.basicInformation.email,
                    urlLogo: r.profile.basicInformation.urlLogo,
                    website: r.profile.basicInformation.website,
                    socialMedia: r.profile.basicInformation.socialMedia
                } })),
                catchError(e => of(UserActions.userInfoFail({ err: e.message })))
            ))
        )
    });
}